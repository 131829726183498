<template>
    <div>
        <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1">
            <h3 class="md-title" style="flex: 1" v-if="vehicleType.type">{{vehicleType.type}}</h3>
            <h3 class="md-title" style="flex: 1" v-else>New vehicle type</h3>
           
            <md-button class="md-raised" @click="addPicture">Add image</md-button>
            <!--md-button class="md-primary">Save vehicle type</md-button-->
            <md-button class="md-primary md-raised" @click="add">Save vehicle type</md-button>
        </md-toolbar>
        <div class="main-inner-wrapper">
            <CRow :gutters="true">
                <CCol sm="12" lg="5">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">Vehicle info</div>
                        </md-card-header>

                        <md-card-content>
                    
                            <CRow>
                                <CCol sm="12">
                                    <md-field :class="getValidationClass('type')">
                                        <label>Name</label>
                                        <md-input v-model="vehicleType.type"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.type.required">Base is required</span>
                                    </md-field>
                                    <md-field>
                                        <label>Description</label>
                                        <md-textarea v-model="vehicleType.description" md-counter="250"></md-textarea>
                                    </md-field>
                                    <md-field :class="getValidationClass('base')">
                                        <md-select v-model="vehicleType.base" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type">
                                            <md-option v-for="vehicleType in baseVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.vehicleType.base.required">Vehicle Type is required</span>
                                    </md-field>
                                </CCol>
                            </CRow>
                            <CRow>
                            
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('passengers')">
                                        <md-icon>people</md-icon>
                                        <label>Passengers</label>
                                        <md-input v-model="vehicleType.passengers" type="number" :max="selectedBase.passengers" :disabled="!selectedBase.passengers"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.passengers.required">Required</span>
                                        <span class="md-error" v-if="!$v.vehicleType.passengers.between">Number must be between 1 and {{selectedBase.passengers}}</span>
                                    </md-field>
                                </CCol>
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('luggage')">
                                        <md-icon>luggage</md-icon>
                                        <label>Luggage</label>
                                        <md-input v-model="vehicleType.luggage" type="number" :max="selectedBase.luggage" :disabled="!selectedBase.luggage"></md-input>
                                        <span class="md-error" v-if="!$v.vehicleType.luggage.required">Required</span>
                                        <span class="md-error" v-if="!$v.vehicleType.luggage.between">Number must be between 1 and {{selectedBase.luggage}}</span>
                                    </md-field>
                                </CCol>
                                
                            </CRow>
                            <md-divider class="mt-3"></md-divider>
                            <CRow >
                                <CCol sm="12">
                                    <md-switch v-model="vehicleType.visible" class="md-primary">Visible</md-switch>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12">
                                    <md-field class="d-none">
                                        <label for="vehicle-pictures">Vehicle images</label>
                                        <md-file ref="carfile" multiple="multiple" name="vehicle-pictures" id="vehicle-pictures" :disabled="sending" @change="imageChanged($event, 'car-picture')"/>
                                    </md-field>
                                    
                                </CCol>
                            </CRow>
                        </md-card-content>
                        
                    </md-card>
                </CCol>
                <CCol sm="12" lg="7">
                    <md-card>
                        <div v-if="files.length > 0">
                            <md-card-header >
                                    <div class="md-title">Vehicle images</div>
                            </md-card-header>
                            <md-card-content >
                                <p class="mb-0">Showing image <b>{{activeIndex + 1}}</b> of <b>{{files.length}}</b></p>    
                                <carousel 
                                            @pageChange="carouselChanged" 
                                            :per-page="1" :mouse-drag="true"  
                                            paginationActiveColor="#f9ba00" 
                                            paginationColor="#c3c3c3" 
                                            paginationPosition="top" 
                                            ref="carousel"
                                            :adjustableHeight="true"
                                        
                                            
                                    >
                                    <slide v-for="picture in files" v-bind:key="picture.id">
                                    <vue-cropper 
                                        
                                            :src="picture.imgSrc"
                                            :aspect-ratio="16 / 9"     
                                            ref="cropper" 
                                            >
                                            
                                        </vue-cropper>
                                    </slide>
                                </carousel> 
                                <div class="d-flex justify-content-start buttons-container mt-3">
                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="zoomIn"><md-icon>zoom_in</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="zoomOut"><md-icon>zoom_out</md-icon></md-button>
                                    </div>
                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="flip"><md-icon>flip</md-icon></md-button>
                                    </div>

                                    <div class="d-inline-flex">
                                        <md-button class="md-dense md-raised" @click="move(-10,0)"><md-icon>arrow_back</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(10,0)"><md-icon>arrow_forward</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(0,-10)"><md-icon>arrow_upward</md-icon></md-button>
                                        <md-button class="md-dense md-raised" @click="move(0,10)"><md-icon>arrow_downward</md-icon></md-button>
                                    </div>
                                    <md-button class="md-raised md-dense position-relative" :class="{ 'remove-button': files.length > 1 }" @click="removePicture"><md-icon class="mr-2">delete</md-icon>Remove image <b>#{{activeIndex + 1}}</b></md-button>
                                </div>
                                
                                

                                                    
                                
                            </md-card-content>
                            
                        </div>
                        <md-card-content v-else>
                            <md-empty-state
                                md-icon="photo_library"
                                md-label="Add vehicle pictures here"
                                md-description="Car pictures will be display in your booking system">
                                <md-button class="md-primary md-raised" @click="addPicture">Add image</md-button>
                            </md-empty-state>
                            <md-field :class="getValidationClass('files')">
                                <span class="md-error" v-if="!$v.files.required">Add at least one image</span>
                            </md-field>
                        </md-card-content>
                    </md-card>
                </CCol>
            </CRow>
        </div>
        
    </div>
</template>

<script>
import Vue from 'vue'
import firebase from '../../Firebase'
import { validationMixin } from 'vuelidate'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {
    required,
    between,
    email,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'
export default {
    name: 'NewVehicleType',
    components: { VueCropper },
    mixins: [validationMixin],
    
    data() {
        return {
            activeIndex: 0,
            sending: false,
            files: [],
            baseVehicleTypes: [],
            selectedBase: {
                passengers: null,
                luggage: null
            },
            vehicleType: {
                order:null,
                type: null,
                passengers: null,
                luggage: null,
                description:null,
                pictures: [],
                visible:true,
                base:null,
                rates: {
                    base: {
                        price: null,
                        includedMiles: null
                    },
                    distance: [
                        
                    ],
                    hourly: [
                        { upto: 1 , minutes: 60 ,price: null},
                        { upto: 2 , minutes: 120 ,price: null},
                        { upto: 3 , minutes: 180 ,price: null},
                        { upto: 4 , minutes: 240 ,price: null},
                        { upto: 5 , minutes: 300 ,price: null},
                        { upto: 6 , minutes: 360 ,price: null},
                        { upto: 7 , minutes: 420 ,price: null},
                        { upto: 8 , minutes: 480 ,price: null},
                        { upto: 9 , minutes: 540 ,price: null},
                        { upto: 10 , minutes: 600 ,price: null},
                        { upto: 11 , minutes: 660 ,price: null},
                        { upto: 12 , minutes: 720 ,price: null}

                    ],
                    hourlyEnabled: false,
                    flat: [

                    ],
                    time: null,
                    traffic: null,
                    waitingTime: null,
                    stop: null,
                    extras: {
                        childSeat: {
                            enabled: false,
                            price:null
                        },
                        accessible: {
                            enabled: false,
                            price:null
                        },
                        meetAndGreet: {
                            enabled: false,
                            price:null
                        }
                    }
                }
            },
            ref:   firebase.firestore().collection('vehicle-types'),
            
           
        }
    },
    
    methods: {
        zoomIn(){
            this.$refs.cropper[this.activeIndex].relativeZoom(0.1);
            
        },
        zoomOut(){
            this.$refs.cropper[this.activeIndex].relativeZoom(-0.1);
        },
        flip(){
            
            const dom =  this.$refs.cropper[this.activeIndex].cropper.canvas;
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper[this.activeIndex].scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        move(offsetX, offsetY) {
            this.$refs.cropper[this.activeIndex].move(offsetX, offsetY);
        },
        
        carouselChanged(index){
            this.activeIndex = index;
        },
        getCanvasBlob(canvas) {
            return new Promise(function(resolve, reject) {
                canvas.toBlob(function(blob) {
                resolve(blob)
                })
            })
        },
        async uploadPictures() {
            let email = this.user.data.email ;
            
            
            const promises = this.files.map(async (file, index) => {

                const canvas = this.$refs.cropper[index].getCroppedCanvas()
                let ref = this.$firebase.storage().ref()
                            .child(`users/`+ email +`/vehicles/${file.file.name}`)
                var blobOutput = await this.getCanvasBlob(canvas);

                
                return ref.put(blobOutput).then(() => ref.getDownloadURL());
                
            })

            return Promise.all(promises)
            .then((uploadedMediaList) => {
                console.log(uploadedMediaList, 'all');
                this.vehicleType.pictures = uploadedMediaList ;
            })
            .catch((err) => alert(err.code));

        },
        removePicture(){
            console.log(this.$refs.carousel.currentPage);
            this.files.splice(this.$refs.carousel.currentPage,1);
        },
        add(){
            
            
            if(this.validate()) {
                // Save vehicle
                this.saveVehicleType()

            } else {
                this.$v.vehicleType.type.$touch()
                this.$v.vehicleType.passengers.$touch()
                this.$v.vehicleType.luggage.$touch()
                this.$v.vehicleType.base.$touch()
                this.$v.files.$touch()
            }
            
            
        },
        async saveVehicleType(){
            let th = this;
            this.sending = true ;
            await this.uploadPictures()
            firebase.firestore().collection('users').doc(this.user.data.uid).collection('vehicle-types')
            
            .add(th.vehicleType)
        
            .then(function(docRef) {
                th.sending = false;
                th.$router.push({path: `/app/vehicle-types`})
            })
            .catch(function(error) {
                th.sending = false;
                console.error("Error adding Tutorial: ", error);
            });
        },
        addPicture()
        {
            this.$refs.carfile.$refs.inputFile.click();
            
        },
        getValidationClass (fieldName) {
            let field = null;

            if(fieldName === 'files') {
                field = this.$v[fieldName];
            } else {
                field = this.$v.vehicleType[fieldName];
            }
            
            
            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        validate(){
            let type,passengers,luggage,pictures,base;
            type = this.$v.vehicleType.type.$invalid;
            passengers = this.$v.vehicleType.passengers.$invalid;
            luggage = this.$v.vehicleType.luggage.$invalid;
            base = this.$v.vehicleType.base.$invalid;
            pictures = this.$v.files.$invalid;

            return !type && !passengers && !luggage && !pictures && !base ? true : false ;


        },
        imageChanged(e, type) {
           
            var newFiles = [];
            for (let i = 0; i < e.target.files.length; i++) {
                
                const file = e.target.files[i];
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {  
                        this.files.push({
                            imgSrc: event.target.result,
                            file: file
                        })
                        
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            }

            this.$refs.carfile.$refs.inputFile.value = '';
        
        },
       
       
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
      
       
    },
    watch: {
        'vehicleType.base': function(id){
            console.log(id)
            for (let i = 0; i < this.baseVehicleTypes.length; i++) {
                if(id == this.baseVehicleTypes[i].id) {
                    this.selectedBase.passengers = this.baseVehicleTypes[i].passengers;
                    this.selectedBase.luggage = this.baseVehicleTypes[i].luggage;
                }
                
            }
        }
    },
    created () {

        this.$firebase.firestore().collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
            this.baseVehicleTypes = [];
            querySnapshot.forEach((doc) => {
                
                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.baseVehicleTypes.push(dataToSave);
                
            });
        });
        // this.ref.orderBy('order').onSnapshot((querySnapshot) => {
        //     this.baseVehicleTypes = [];
        //     querySnapshot.forEach((doc) => {

        //         let dataToSave = doc.data();
        //         dataToSave.id = doc.id ;
        //         this.baseVehicleTypes.push(dataToSave);
                
        //     });
        // });
    },
    validations: {
        files: {
            required,
            minLength: minLength(1),
        },
        vehicleType: {
            base: {
                required
            },
            type: {
                required
            },
            passengers: {
                required,
                between (value) {
                    return between(1, this.selectedBase.passengers)(value)
                }
                
            },
            luggage: {
                required,
                between (value) {
                    return between(1, this.selectedBase.luggage)(value)
                }
            },
            pictures: {
                required,
                minLength: minLength(1),
            }
        }
    }
}
</script>  
<style>
    .VueCarousel-pagination {
        text-align: left!important;
    }

    .remove-button {
        
    }

    .buttons-container .md-button{
        min-width: initial;
    }

    .VueCarousel-slide-adjustableHeight {
        display: block !important;
    }
    .VueCarousel-inner {
        align-items: flex-start !important;
    }

    .VueCarousel-dot-container {
        margin-top: 0px!important;
    }

    .VueCarousel-dot {
        margin-top: 0px!important;
        margin-bottom: 0px!important;
    }

</style>